<template>
  <ul class="dp-pro-recipe-material-list">
    <DpProRecipeMaterialListItem
      v-for="(content, index) in contents"
      :key="index"
      :content="content" />
  </ul>
</template>

<script>
import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  components: {
    DpProRecipeMaterialListItem: () =>
      import('@/components/dripPod/proRecipe/MaterialListItem.vue')
  },

  props: {
    contents: {
      type: Array,
      default: null
    }
  }
});
</script>

<style lang="scss" scoped>
@use '@/assets/scss/variables';
@use '@/assets/scss/mixin';

.dp-pro-recipe-material-list {
  margin-bottom: 0;
  font-size: 14px;
  line-height: 1.4;
}
</style>
