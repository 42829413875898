var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ul",
    { staticClass: "dp-pro-recipe-material-list" },
    _vm._l(_vm.contents, function (content, index) {
      return _c("DpProRecipeMaterialListItem", {
        key: index,
        attrs: { content: content },
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }